<template>
    <section class="marketing-financial p-3 p-lg-5">
        <h3 class="marketing-financial__title">
            {{ getMarketingPageInfo('title') }}
        </h3>
        <div class="marketing-financial__text mt-4">
            {{ getMarketingPageInfo('text') }}
        </div>

        <div class="d-flex flex-column flex-lg-row mt-4 position-relative">
            <ul class="col-12 col-lg-6 ps-0 ps-lg-4">
                <li
                    v-for="(item, index) in getFirstPartOfPoints()"
                    :key="index"
                    class="d-flex align-items-center mt-3"
                >
                    <img src="../../assets/views/technopark/check.svg" alt="">
                    <div
                        class="ms-3 accelerator-programs__text"
                        v-html="item"
                    >
                    </div>
                </li>
            </ul>

            <ul class="col-12 col-lg-6 ps-0 ps-lg-4">
                <li
                    v-for="(item, index) in getLastPartOfPoints()"
                    :key="index"
                    class="d-flex align-items-center mt-3"
                >
                    <img src="../../assets/views/technopark/check.svg" alt="">
                    <div
                        class="ms-3 accelerator-programs__text"
                        v-html="item"
                    >
                    </div>
                </li>
            </ul>

            <div class="marketing-financial__decor-img position-absolute">
                <img src="../../assets/views/project_expertise/mf-decoration.svg" alt="">
            </div>
        </div>
    </section>
</template>

<script>
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "MarketingFinancial",

    setup() {
        const store = useStore()

        store.dispatch('pages/getProjectsExpertisePage')
        const projectsExpertisePage = computed(() => store.getters['pages/projectsExpertisePage'])

        const getMarketingPageInfo = (key) => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.first_tab[key]
            }

            return ''
        }

        const getFirstPartOfPoints = () => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.first_tab.points_split.slice(0,6)
            }

            return []
        }

        const getLastPartOfPoints = () => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.first_tab.points_split.slice(6)
            }

            return []
        }

        return {
            getMarketingPageInfo,
            getFirstPartOfPoints,
            getLastPartOfPoints
        }
    }
}
</script>
